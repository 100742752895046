import { useRef, useState } from "react"

import { Grid, Stack } from "@mui/material"
import { useQueryClassesById } from "api/reactQuery/queries/classes"
import ListingSortingHeader from "components/common/listing/listingSortingHeader"
import { useAppDispatch } from "hooks/store"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { setIsNextPage } from "store/utility/utility.slice"
import { showDecimal } from "utils/decimalNumber"
import { getSortingTextSecond } from "utils/listing"

import { sortingOptions, sortingTextMap } from "../ClassStudentsTeacher.config"
import ViewCommonElementsContainer from "../common/viewCommonElementsContainer"
import { HandleFunction } from "../common/viewCommonElementsContainer/ViewCommonElementsContainer"
import { Sort, SortingType } from "../tableView/TableView"
import StudentItemCard from "./studentItemCard"

const GridView = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isToggleAll, setIsToggleAll] = useState(false)
  const [sortBy, setSortBy] = useState<Sort>("first_name")
  const [sorting, setSorting] = useState<SortingType>("desc")
  const [file, setFile] = useState("")
  const params = useParams()
  const refetchRef = useRef<HandleFunction | null>(null)

  const { data: classesByIdData } = useQueryClassesById({
    id: Number(params.classId),
  })

  const savingsAccountOption = classesByIdData?.data.savings_account_option

  return (
    <ViewCommonElementsContainer
      stateFile={{ file, setFile }}
      setIsToggle={setIsToggleAll}
      ref={refetchRef}
      sortBy={sortBy}
      sorting={sorting}
      isToggleAll={isToggleAll}
      renderDataFromBE={({
        apiData,
        selectingProps: {
          selectedItems,
          toggleAll,
          clearSelectedItems,
          excludedItems,
          setExcludedItems,
        },
      }) => (
        <ListingSortingHeader
          sortingText={t(getSortingTextSecond(sortBy, sortingTextMap))}
          defaultRender={sortBy}
          sortingDirection={sorting}
          sortingActive={!!sortBy}
          onSubmit={(data) => {
            const dataSortReturn = JSON.parse(data.sort)
            const { order, orderBy } = dataSortReturn
            setSortBy(orderBy)
            setSorting(order)
            dispatch(setIsNextPage(true))
            if (refetchRef.current) {
              refetchRef.current?.triggerRefetch()
            }
            clearSelectedItems()
          }}
          options={sortingOptions}
          checkboxProps={{
            checked:
              apiData.length === selectedItems.length && !!apiData?.length,
            onChange: () => {
              if (!!file.length && isToggleAll) {
                toggleAll()
                if (apiData.length !== selectedItems.length) {
                  const filteredArr = excludedItems.filter((element) => {
                    return !apiData.some((copyElement) => {
                      return element.id === copyElement.id
                    })
                  })
                  setExcludedItems(filteredArr)
                  return
                }
                setExcludedItems((prev) => {
                  const newItems = apiData.filter(
                    (item) =>
                      !prev.some((excludedItem) => excludedItem.id === item.id)
                  )
                  return [...prev, ...newItems]
                })
                return
              }

              toggleAll()
              if (!!excludedItems.length) {
                return
              }
              setIsToggleAll(!isToggleAll)
            },
            indeterminate:
              apiData.length !== selectedItems.length && !!selectedItems.length,
          }}
        />
      )}
      renderDataFromBEOutside={({
        apiData,
        selectingProps: { toggleItem, getIsChecked, toggleExcludedItems },
      }) => (
        <Stack bgcolor="mockup.primary99" mt="16px">
          <Grid
            container
            columnSpacing={{ mobile: "8px", tablet: "16px" }}
            rowSpacing={{ mobile: "16px", tablet: "24px" }}
          >
            {apiData.map((student) => (
              <Grid
                key={student.id}
                item
                mobile={6}
                tablet={4}
                desktop={3}
                sx={(theme) => ({
                  [theme.breakpoints.down(480)]: {
                    flexBasis: "100%",
                    maxWidth: "100%",
                  },
                })}
              >
                <StudentItemCard
                  savingsAccountOption={savingsAccountOption}
                  fullName={`${student.first_name} ${student.last_name}`}
                  jobs={student.jobs}
                  titleSavings={t("students.savings")}
                  titleBookedBalance={t("students.checkings")}
                  titleTotalBalance={t("students.balance")}
                  titlePendingBalance={t("students.pending")}
                  valueSavings={showDecimal(`${student.savings}`) as number}
                  valueBookedBalance={
                    showDecimal(`${student.booked_balance}`) as number
                  }
                  valueTotalBalance={
                    showDecimal(`${student.balance}`) as number
                  }
                  valuePendingBalance={
                    showDecimal(`${student.pending_balance}`) as number
                  }
                  avatar={student.avatar_url}
                  checkboxProps={{
                    checked: getIsChecked(student),
                    onChange: () => {
                      toggleItem(student)
                    },
                    onClick: () => {
                      if (isToggleAll) {
                        toggleExcludedItems(student)
                      }
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    />
  )
}

export default GridView
