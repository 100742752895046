import { IPostResetBalance } from "api/reactQuery/mutations/balancesReset.types"
import { IPostBulkDeleteRemoveStudents } from "api/reactQuery/mutations/students.types"
import { IObjectStudentDetailed } from "ts/interfaces/Student"

interface IFilterClassList {
  classlist: IObjectStudentDetailed[]
  variables: IPostBulkDeleteRemoveStudents
}

interface IFilterBalanceClassList {
  classlist: IObjectStudentDetailed[]
  balanceVariable: IPostResetBalance
}

export const useFilterClassList = ({
  classlist,
  variables,
}: IFilterClassList) => {
  const updateClasslist = classlist.filter((student) => {
    if (variables?.data.selected_ids) {
      return student.id !== variables.data.selected_ids[0]
    }
  })

  const filteredState = classlist.filter((oldStudent) =>
    updateClasslist.some((newStudent) => oldStudent.id === newStudent.id)
  )

  return {
    filteredState,
  }
}

export const userFilterBalanceClasslist = ({
  classlist,
  balanceVariable,
}: IFilterBalanceClassList) => {
  const findStudent = classlist.find(
    (student) =>
      student.id ===
      (balanceVariable?.selected_ids && balanceVariable.selected_ids[0])
  )

  const filteredBalance = classlist.map((student) => {
    if (student.id === findStudent?.id) {
      return {
        ...findStudent,
        balance: findStudent.balance - findStudent.booked_balance,
        booked_balance: 0,
      }
    } else {
      return student
    }
  })
  return {
    filteredBalance,
  }
}

export const useFilterClassListArray = ({
  classlist,
  variables,
}: IFilterClassList) => {
  const variable_selected = variables?.data.selected_ids
  const variable_excluded = variables?.data.excluded_ids
  const isSelectedAll = variables?.data.all_selected

  const updateClasslist = classlist.filter((student) => {
    if (isSelectedAll && variable_excluded) {
      return variable_excluded.some((variable) => {
        return student.id === variable
      })
    }

    if (!isSelectedAll && variable_selected) {
      return !variable_selected.some((variable) => {
        return student.id === variable
      })
    }
  })

  const filteredState = classlist.filter((oldStudent) =>
    updateClasslist.some((newStudent) => oldStudent.id === newStudent.id)
  )

  return {
    filteredState,
  }
}

export const userFilterBalanceClasslistArray = ({
  classlist,
  balanceVariable,
}: IFilterBalanceClassList) => {
  const balanceVariable_selected = balanceVariable?.selected_ids
  const balanceVariable_excluded = balanceVariable?.excluded_ids
  const balancedIsSelectedAll = balanceVariable?.all_selected

  const filteredBalance = classlist.filter((student) => {
    if (balancedIsSelectedAll && !balanceVariable_excluded?.length) {
      return classlist
    }

    if (balancedIsSelectedAll && balanceVariable_excluded) {
      return !balanceVariable_excluded.includes(student.id)
    }

    if (!balancedIsSelectedAll && balanceVariable_selected) {
      return balanceVariable_selected?.includes(student.id)
    }
  })

  const updatedBalance = classlist.map((obj) => {
    const patch = filteredBalance.find((student) => student.id === obj.id)
    return patch
      ? {
          ...patch,
          balance: patch.balance - patch.booked_balance,
          booked_balance: 0,
        }
      : obj
  })

  return {
    updatedBalance,
  }
}
