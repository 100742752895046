export enum TypeTransaction {
  SALARY = "salary",
  EXPENSE = "expense",
  BONUS = "bonus",
  FINE = "fine",
  PURCHASE = "purchase",
  REFUND = "refund",
  RESET = "reset",
}

export enum AccountTransactionType {
  ALL_TYPES = "all_types",
  SALARY = "salary",
  EXPENSE = "expense",
  BONUS = "bonus",
  FINE = "fine",
  PURCHASE = "purchase",
  REFUND = "refund",
  RESET = "reset",
  TRANSFER = "transfer",
  INTEREST = "interest",
}
