import React, { useEffect, useState } from "react"

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material"
import { useQueryAllTransactions } from "api/reactQuery/queries/transaction"
import { IStudentAllTransactions } from "api/reactQuery/queries/transaction.types"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { Type } from "ts/enums/Filtration"

import { filterTransactions, IFilter } from "../studentProfile.config"
import MobileTableContainer from "./mobileTable/mobileTableContainer"
import Table from "./tableHeadRow/Table"

interface ITransactions {
  filterClose: () => void
  filterPopover: HTMLButtonElement | null
}

const Transactions = ({ filterPopover, filterClose }: ITransactions) => {
  const isTablet = useIsBreakpointUp("tablet")
  const params = useParams()
  const { t } = useTranslation()
  const [radioButton, setRadioButton] = useState<
    Type.pending | Type.allTransactions
  >(Type.allTransactions)
  const [selectedItems, setSelectedItems] = useState<IFilter>(
    structuredClone(filterTransactions)
  )
  const [arrayFiltersName, setArrayFiltersName] = useState<Type[]>([])
  const [isApply, setIsApply] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [copyArrayFiltersName, setCopyArrayFiltersName] = useState<Type[]>([])
  const [isNextTranasactions, setIsNextTransactions] = useState(true)
  const [pageTransactions, setPageTransactions] = useState(0)
  const [dataTransactions, setDataTransactions] = useState<
    IStudentAllTransactions[]
  >([])

  const { refetch, isFetching, isLoading } = useQueryAllTransactions({
    student_id: Number(params.studentId),
    page: pageTransactions,
    limit: 20,
    is_pending: isPending,
    type: arrayFiltersName,
    options: {
      cacheTime: 0,
      enabled: !!params.studentId,
      onSuccess: (data) => {
        if (data?.data.length !== 20) {
          setIsNextTransactions(false)
          setPageTransactions(0)
        } else {
          setIsNextTransactions(true)
        }

        if (!!pageTransactions) {
          setDataTransactions((prev) => [...prev, ...data?.data])
        } else {
          setDataTransactions(data?.data)
        }
      },
    },
  })

  useEffect(() => {
    if (radioButton === Type.pending) {
      setIsPending(true)
    } else {
      setIsPending(false)
    }
  }, [radioButton])

  useEffect(() => {
    if (pageTransactions !== 0) {
      refetch()
    }
  }, [pageTransactions])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: string
  ) => {
    if (type === "type") {
      const update = [...selectedItems["type"]]
      update[index].check = e.target.checked
      setSelectedItems((prev) => ({
        ...prev,
        type: update,
      }))
    }
  }

  const resetFilters = () => {
    const updatedStatus = [
      ...selectedItems["status"].map((el) => ({ ...el, check: false })),
    ]
    const updatedType = [
      ...selectedItems["type"].map((el) => ({ ...el, check: false })),
    ]

    setSelectedItems({ status: updatedStatus, type: updatedType })
    setArrayFiltersName([])
  }

  const deleteSingleFilter = (name: string) => {
    const copySelectedFilters = [...arrayFiltersName]
    const updatedType = [
      ...selectedItems["type"].map((el) => {
        if (el.name === name) {
          return { ...el, check: false }
        }
        return el
      }),
    ]
    setSelectedItems((prev) => ({ ...prev, type: updatedType }))

    const res = copySelectedFilters.filter(
      (checkedName) => checkedName !== name
    )
    setArrayFiltersName(res)
    setCopyArrayFiltersName(res)
  }

  const setFiltersName = (name: Type) => {
    const update = [...arrayFiltersName]
    const res = update.indexOf(name)
    if (res !== -1) {
      update.splice(res, 1)
    } else {
      update.push(name)
    }
    setArrayFiltersName(update)
  }

  return (
    <Box width="100%" my={2}>
      <Stack
        padding="12px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {isApply && (
            <Stack direction="row" columnGap={2} pt={1}>
              {copyArrayFiltersName.map((name) => (
                <Stack
                  onClick={() => {
                    deleteSingleFilter(name)
                    setTimeout(() => {
                      refetch()
                    }, 0)
                  }}
                  direction="row"
                  alignItems="center"
                  key={name}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.mockup.neutral90,
                    color: theme.palette.mockup.neutral10,
                    borderRadius: "8px",
                    p: "4px",
                    cursor: "pointer",
                  })}
                >
                  <Typography variant="body3" pr={1}>
                    {name.toUpperCase()}
                  </Typography>
                  <Typography variant="body3">x</Typography>
                </Stack>
              ))}
              {!!copyArrayFiltersName.length && (
                <Button
                  sx={{ p: "5px", minHeight: "15px", fontSize: "12px" }}
                  variant="text"
                  onClick={() => {
                    resetFilters()
                    setIsApply(false)
                    setPageTransactions(0)
                    setArrayFiltersName([])
                    setTimeout(() => {
                      refetch()
                    }, 0)
                  }}
                >
                  {t("studentProfile.resetFilters")}
                </Button>
              )}
            </Stack>
          )}
        </Box>

        <Popover
          open={!!filterPopover}
          anchorEl={filterPopover}
          onClose={filterClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Stack sx={{ pt: 4, pb: "26px", px: 4, width: "274px" }}>
            <Stack>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Typography pb={1} variant="body1" color="mockup.neutral10">
                    {t("studentProfile.status")}
                  </Typography>
                </FormLabel>
                <RadioGroup
                  value={radioButton}
                  onChange={(e) =>
                    setRadioButton(
                      e.target.value as Type.allTransactions | Type.pending
                    )
                  }
                >
                  {selectedItems["status"].map((element, index) => (
                    <FormControlLabel
                      sx={{ py: 1 }}
                      key={index}
                      value={element.name}
                      control={<Radio />}
                      labelPlacement="end"
                      label={
                        <Typography
                          variant="body1"
                          color="mockup.neutral0"
                          fontWeight="500"
                        >
                          {t(`studentProfile.${element.name}`)}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Typography
                    pt={2}
                    pb={1}
                    variant="body1"
                    color="mockup.neutral10"
                  >
                    {t("studentProfile.transactionType")}
                  </Typography>
                </FormLabel>
                <FormGroup>
                  {selectedItems["type"].map((element, index) => (
                    <FormControlLabel
                      sx={{ py: 1 }}
                      key={index}
                      control={
                        <Checkbox
                          checked={element.check}
                          onChange={(e) => {
                            handleChange(e, index, "type")
                            setFiltersName(element.name)
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          color="mockup.neutral0"
                          fontWeight="500"
                        >
                          {t(`studentProfile.${element.name}`)}
                        </Typography>
                      }
                      labelPlacement="end"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Stack>
          </Stack>
          <Stack mx={4} mb="44px" sx={{ position: "relative" }}>
            <Button
              disabled={isFetching}
              onClick={() => {
                Object.values(
                  selectedItems["type"].some((filter) => {
                    if (filter.check) {
                      setIsApply(true)
                    }
                  })
                )
                setPageTransactions(0)
                setCopyArrayFiltersName(arrayFiltersName)
                setTimeout(() => {
                  refetch()
                }, 0)
              }}
            >
              {t("studentProfile.apply")}
            </Button>
            <Button
              onClick={() => {
                resetFilters()
                setIsApply(false)
                setPageTransactions(0)
                setArrayFiltersName([])
                setTimeout(() => {
                  refetch()
                }, 0)
              }}
              variant="text"
            >
              {t("studentProfile.resetFilters")}
            </Button>
          </Stack>
        </Popover>
      </Stack>
      <Box
        {...(isFetching && {
          sx: { opacity: "0.5", position: "relative", pointerEvent: "none" },
        })}
      >
        {isFetching && (
          <Box
            component="span"
            sx={{
              position: "absolute",
              top: "100px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {isTablet ? (
          <Table
            copyArrayFiltersName={copyArrayFiltersName}
            limitCounter={20}
            setDataTransactions={setDataTransactions}
            data={dataTransactions}
            isLoading={isLoading}
            isFetching={isFetching}
            setNextTransactions={setPageTransactions}
            isNextTransactions={isNextTranasactions}
          />
        ) : (
          <MobileTableContainer
            copyArrayFiltersName={copyArrayFiltersName}
            limitCounter={20}
            setDataTransactions={setDataTransactions}
            data={dataTransactions}
            isLoading={isLoading}
            isFetching={isFetching}
            setNextTransactions={setPageTransactions}
            isNextTransactions={isNextTranasactions}
          />
        )}
      </Box>
    </Box>
  )
}

export default Transactions
