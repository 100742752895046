import React from "react"

import { Box, DialogProps, Stack, Typography, useTheme } from "@mui/material"
import { StudentSavingsTypes } from "api/reactQuery/queries/savings.types"
import Dialog from "components/common/dialog/dialog"
import Coin from "components/common/icon/coin"

interface GoalDialogProps extends Omit<DialogProps, "open"> {
  isOpen: boolean
  handleClose: () => void
  titleText: string
  titleBeforeSlot: React.ReactNode
  onActionButtonClick?: () => void
  dataClass: StudentSavingsTypes | null
  goalName: string
  current_amount: number
  isWithdraw?: boolean
  isLoading?: boolean
}

const GoalDialog: React.FC<GoalDialogProps> = ({
  isOpen,
  handleClose,
  titleText,
  titleBeforeSlot,
  onActionButtonClick,
  current_amount,
  isWithdraw,
  children,
  dataClass,
  isLoading,
  ...dialogProps
}) => {
  const theme = useTheme()
  const availableAmount = isWithdraw
    ? current_amount
    : Number(dataClass?.available_amount_to_transfer)

  return (
    <Dialog
      titleText={titleText}
      open={isOpen}
      onClose={handleClose}
      titleBeforeSlot={titleBeforeSlot}
      onActionButtonClick={onActionButtonClick}
      actionAcceptButtonProps={{ disabled: isLoading }}
      {...dialogProps}
    >
      <Stack rowGap={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            backgroundColor: theme.palette.mockup.secondary90,
            py: 2,
            mx: "-32px",
            px: "32px",
          }}
        >
          <Typography color="mockup.grey70" variant="subtitle1">
            {"Available for goals:"}
          </Typography>
          <Coin
            amount={availableAmount}
            iconSize="24px"
            valueTextProps={{
              fontSize: "28px",
              color: theme.palette.mockup.grey70,
            }}
          />
        </Box>
        <Stack rowGap={3}>{children}</Stack>
      </Stack>
    </Dialog>
  )
}
export default GoalDialog
