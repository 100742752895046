import { ReactComponent as IconAnonymous } from "@common_assets/svg/anonymousDashboard.svg"
import { Stack, Typography } from "@mui/material"
import { useQueryStudentJobs } from "api/reactQuery/queries/studentJobs"
import ErrorText from "components/common/error/errorText"
import NoElementMessageWithoutBtn from "components/common/listing/noElementMessageWithoutBtn"
import TableSkeleton from "components/common/skeleton/tableSkeleton"
import { useTranslation } from "react-i18next"
import { PrimaryTile } from "styles/common/tile"

import JobItem from "./jobItem"

interface IMyJobsProps {
  hasSavingAccount: boolean | null
}

const MyJobs = ({ hasSavingAccount }: IMyJobsProps) => {
  const { t } = useTranslation()

  const { data, isLoading, isError } = useQueryStudentJobs()

  return (
    <PrimaryTile py="16px" px={{ mobile: "16px", desktop: "24px" }}>
      <Stack mb="16px">
        <Typography fontSize="24px" lineHeight="36px" color="mockup.neutral10">
          {t("dashboardStudent.myJobs")}
        </Typography>
      </Stack>
      <Stack
        gap="16px"
        maxHeight={hasSavingAccount ? "30vh" : "calc(100vh - 225px)"}
        sx={{ overflowY: "auto" }}
        className="smooth-scrollbar"
      >
        {data?.data.map((job) => (
          <JobItem key={job.id} job={job} />
        ))}
      </Stack>
      {isLoading && <TableSkeleton />}
      {isError && <ErrorText />}
      {!isLoading && data?.data.length === 0 && (
        <Stack my="32px">
          <NoElementMessageWithoutBtn
            title={t("dashboardStudent.youDoNotHaveJobsYet")}
            description={t("dashboardStudent.goToTheJobBoard")}
            Icon={<IconAnonymous />}
          />
        </Stack>
      )}
    </PrimaryTile>
  )
}

export default MyJobs
