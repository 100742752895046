import React, { FC } from "react"

import { Box } from "@mui/material"
import { useQueryClassesById } from "api/reactQuery/queries/classes"
import { useClassId, useGetCurrentTabName } from "hooks/navigation"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { ITab } from "ts/interfaces/Tab"

import { StyledTab, StyledTabs } from "./ContentTabs.styles"

interface IProps {
  tabs: ITab[]
}

const ContentTabs: FC<IProps> = ({ tabs }) => {
  const { t } = useTranslation()
  const tabName = useGetCurrentTabName(tabs)
  const classId = useClassId()

  const { data: classOptions } = useQueryClassesById({
    id: Number(classId),
    options: {
      enabled: tabName === "bills",
    },
  })

  return (
    <StyledTabs value={tabName} variant="scrollable" scrollButtons={false}>
      {tabs.map(({ labelKey, tabName, path }) => (
        <StyledTab
          key={labelKey}
          label={t(labelKey)}
          component={NavLink}
          to={path}
          value={tabName}
        />
      ))}
      {tabName === "bills" && (
        <Box
          lineHeight="16px"
          alignSelf="center"
          bgcolor="mockup.error90"
          p="4px 8px"
          borderRadius="6px"
          maxHeight="24px"
          color="mockup.error50"
        >
          {`${t("setup.autoExpense").toUpperCase()}: ${
            classOptions?.data.expense_automatic ? t("jobs.on") : t("jobs.off")
          } `}
        </Box>
      )}
    </StyledTabs>
  )
}

export default ContentTabs
