import React, { useEffect, useState } from "react"

import { Stack, Typography } from "@mui/material"
import { StudentSavingsTypes } from "api/reactQuery/queries/savings.types"
import { useDialog } from "hooks/dialog"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setAccount } from "store/savings/savings.slice"

import Dialog from "../../../../common/dialog/dialog"
import Checking from "./checking"
import Savings from "./savings"

interface ITabs {
  onChangeClass: () => void
  data?: StudentSavingsTypes[]
}

const Tabs = ({ data, onChangeClass }: ITabs) => {
  const dispatch = useDispatch()
  const [isActiveNumber, setIsActiveNumber] = useState(
    (data && data[0].id) ?? 0
  )
  const { isOpen, handleOpen } = useDialog()
  const navigate = useNavigate()
  const { t } = useTranslation()
  useEffect(() => {
    if (!data?.length) {
      handleOpen()
    }
  }, [])

  useEffect(() => {
    if (data && isActiveNumber !== 0) {
      const dataClass = data.find(
        (accountClass) => accountClass.id === isActiveNumber
      )

      if (dataClass) {
        dispatch(setAccount(dataClass))
        onChangeClass()
      } else {
        dispatch(setAccount(null))
      }
    }

    if (isActiveNumber === 0) {
      dispatch(setAccount(null))
      onChangeClass()
    }
  }, [isActiveNumber])

  return (
    <Stack rowGap={2}>
      <Checking
        id={0}
        onClick={(e) => setIsActiveNumber(e)}
        isGoal={false}
        iconName={"bill"}
        title="Checking Account"
        amount={!!data?.length ? data[0].checking_balance : 0}
        isActiveNumber={isActiveNumber}
      />
      <Savings>
        <Typography fontSize="24px" color="mockup.neutral10" pt={1}>
          {t("myActivity.yourSavings")}
        </Typography>
        {data?.map((item) => (
          <Checking
            isActiveNumber={isActiveNumber}
            id={item.id as number}
            onClick={(e) => {
              setIsActiveNumber(e)
            }}
            iconName={item.icon}
            goalCount={item.number_of_goals}
            key={item.id}
            amount={item.balance}
            title={item.name}
            rateInterest={item.saving_interest_rate}
            intervalInterest={item.interest_granting_interval}
            isInterestOption={item.interest_option}
          />
        ))}
      </Savings>
      <Dialog
        titleText="You don't belong to any saving account class"
        open={isOpen}
        actionAcceptText="Reload"
        onActionButtonClick={() => navigate(0)}
        onIconClose={false}
      >
        <Typography>
          {t("myActivity.pleaseReloadYourSiteToGetChanges")}
        </Typography>
      </Dialog>
    </Stack>
  )
}
export default Tabs
