import { ReactComponent as Expenses } from "@brand_assets/svg/expenses.svg"
import { ReactComponent as Banknote } from "@common_assets/svg/banknote.svg"
import { ReactComponent as Finest } from "@common_assets/svg/finest.svg"
import { ReactComponent as GreenDollar } from "@common_assets/svg/greenDollar.svg"
import { ReactComponent as Purchase } from "@common_assets/svg/purchase.svg"
import { ReactComponent as Refund } from "@common_assets/svg/refund.svg"
import { ReactComponent as Reset } from "@common_assets/svg/reset.svg"
import { ReactComponent as BriefCase } from "@common_assets/svg/suitcase.svg"
import { Box, useTheme } from "@mui/material"
import { IResponseStudentTransactionsBooked } from "api/reactQuery/queries/studentTransactionsBooked.types"
import {
  IStudentTransactionTeacherSide,
  ITeacherTransactions,
} from "api/reactQuery/queries/transaction.types"
import { TypeTransaction } from "ts/enums/Transaction"
import { IPendingTransactionStudent } from "ts/interfaces/Transaction"

interface IProps {
  transaction:
    | IPendingTransactionStudent
    | IResponseStudentTransactionsBooked
    | IStudentTransactionTeacherSide
    | ITeacherTransactions
}

const TransactionType = ({ transaction }: IProps) => {
  const theme = useTheme()
  const boxStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    width: "44px",
    height: "44px",
    backgroundColor: theme.palette.mockup.primary95,
  }

  return (
    <>
      {transaction.type === TypeTransaction.BONUS &&
      process.env.REACT_APP_TYPE === "classequity" ? (
        <GreenDollar />
      ) : (
        transaction.type === TypeTransaction.BONUS && (
          <Box sx={boxStyle}>
            <Banknote style={{ width: "28px", height: "28px" }} />
          </Box>
        )
      )}
      {transaction.type === TypeTransaction.FINE && <Finest />}
      {transaction.type === TypeTransaction.EXPENSE && <Expenses />}
      {transaction.type === TypeTransaction.SALARY && <BriefCase />}
      {transaction.type === TypeTransaction.PURCHASE && <Purchase />}
      {transaction.type === TypeTransaction.REFUND && <Refund />}
      {transaction.type === TypeTransaction.RESET && <Reset />}
    </>
  )
}

export default TransactionType
