import React, { Dispatch, FC, useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  IPostCreateJobsItem,
  IPostResponseCreateJobs,
} from "api/reactQuery/mutations/jobs.types"
import { IGetResponseJobsDetails } from "api/reactQuery/queries/jobs.types"
import ArrowButton from "components/common/button/arrowButton"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { regexNumberAndCommon } from "utils/constants"
import { showDecimal } from "utils/decimalNumber"

import RichEditor from "../RichEditor"
import { initialStateDetailsJob } from "../utily"
import { defaultValues, schemaAddJobs } from "./AddJob.config"
import { TypesError } from "./AddJob.types"

interface IProps {
  id?: string
  createJob?: (state: IPostCreateJobsItem) => void
  editJobsDetails?: (state: IPostResponseCreateJobs) => void
  jobsState?: IGetResponseJobsDetails
  setJobsState?: Dispatch<React.SetStateAction<IGetResponseJobsDetails>>
  setRichEditorResponsibilities: Dispatch<React.SetStateAction<string>>
  richEditorQualifications: string
  setRichEditorQualifications: Dispatch<React.SetStateAction<string>>
  richEditorResponsibilities: string
  getJobDetails?: IGetResponseJobsDetails
  classesId?: number[]
  setId?: Dispatch<React.SetStateAction<number | null>>
  currentId?: number | null
}

const AddJob: FC<IProps> = ({
  getJobDetails,
  editJobsDetails,
  id,
  richEditorResponsibilities,
  setRichEditorResponsibilities,
  createJob,
  jobsState,
  setJobsState,
  richEditorQualifications,
  setRichEditorQualifications,
  classesId,
  setId,
  currentId,
}) => {
  const [state, setState] = useState<IGetResponseJobsDetails>(() =>
    jobsState ? jobsState : initialStateDetailsJob
  )
  const { t } = useTranslation()
  const params = useParams()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("desktop"))
  const [isDisplayArrow, setIsDisplayArrow] = useState({
    left: false,
    right: false,
  })

  const methods = useForm({
    resolver: yupResolver(schemaAddJobs),
    defaultValues: defaultValues,
  })

  const errorOptions = methods.formState.errors

  const nextJob = () => {
    const findIndexOfAllClasses = classesId?.findIndex((el) => el === currentId)
    const copyOfArray = [...(classesId as number[])]
    setId && setId(copyOfArray[(findIndexOfAllClasses as number) + 1])
  }

  const prevJob = () => {
    const findIndexOfAllClasses = classesId?.findIndex((el) => el === currentId)
    const copyArray = [...(classesId as number[])]
    setId && setId(copyArray[(findIndexOfAllClasses as number) - 1])
  }

  useEffect(() => {
    const findIndexOfAllClasses = classesId?.findIndex((el) => el === currentId)
    if (findIndexOfAllClasses === 0) {
      setIsDisplayArrow((prev) => ({ ...prev, left: true }))
    } else {
      setIsDisplayArrow((prev) => ({ ...prev, left: false }))
    }
    if (findIndexOfAllClasses === (classesId?.length as number) - 1) {
      setIsDisplayArrow((prev) => ({ ...prev, right: true }))
    } else {
      setIsDisplayArrow((prev) => ({ ...prev, right: false }))
    }
  }, [id])

  useEffect(() => {
    if (jobsState) {
      methods.setValue("title", jobsState.title)
      methods.setValue("salary", jobsState.salary as string)
    }
  }, [jobsState])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value

    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    setJobsState && setJobsState(state)
  }, [state])

  useEffect(() => {
    if (state.positions === "") {
      setState((prev) => ({ ...prev, open_for_all: true }))
    } else {
      setState((prev) => ({ ...prev, open_for_all: false }))
    }
  }, [state.positions])

  const addJob = () => {
    if (createJob && id === "addNewJob") {
      createJob({
        classId: Number(params.classId),
        data: [
          {
            title: state.title,
            open_for_all: state.open_for_all,
            salary: Number(showDecimal(state.salary)),
            qualifications: richEditorQualifications,
            responsibilities: richEditorResponsibilities,
            positions: state.open_for_all ? null : state.positions,
          },
        ],
      })
    }

    if (editJobsDetails && id === "editJob") {
      editJobsDetails({
        classId: Number(params.classId),
        data: [
          state.title === getJobDetails?.title
            ? {
                positions: state.open_for_all ? null : state.positions,
                responsibilities: richEditorResponsibilities,
                salary: showDecimal(state.salary),
                qualifications: richEditorQualifications,
                open_for_all: state.open_for_all,
                id: state.id,
              }
            : {
                title: state.title,
                positions: state.open_for_all ? null : state.positions,
                responsibilities: richEditorResponsibilities,
                salary: showDecimal(state.salary),
                qualifications: richEditorQualifications,
                open_for_all: state.open_for_all,
                id: state.id,
              },
        ],
      })
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_TYPE === "bankeeonline")
      document.documentElement.style.setProperty("--bankeeColor", "#CBE7E6")
  }, [])

  return (
    <form id={id} onSubmit={methods.handleSubmit(addJob)}>
      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 250px)",
          padding: "5px",
        }}
        className="smooth-scrollbar"
      >
        <Grid container columnSpacing="10px">
          <Grid item desktop={6} tablet={12} mobile={12} mb={isTablet ? 3 : 0}>
            <TextField
              {...methods.register("title", {
                onChange: handleChange,
              })}
              autoFocus={!!state.title}
              error={Boolean(methods.formState.errors.title)}
              name="title"
              value={state.title}
              type="text"
              label={t("jobs.jobTitle")}
              inputProps={{
                maxLength: 50,
              }}
            />
            {methods.formState.errors.title && (
              <Typography color="mockup.error50" fontWeight="500">
                {t("validations.enterMinChars_other", { count: 3 })}
              </Typography>
            )}
          </Grid>
          <Grid item desktop={3} tablet={6} mobile={6}>
            <TextField
              {...methods.register("positions", {
                onChange: handleChange,
              })}
              name="positions"
              error={Boolean(methods.formState.errors.positions)}
              value={state.positions === null ? "" : state.positions}
              type="number"
              onKeyPress={(event) => {
                if (!/\d/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              onPaste={(e) => e.preventDefault()}
              label={t("jobs.positions")}
            />
            {methods.formState.errors.positions && (
              <Typography color="mockup.error50" fontWeight="500">
                {t("validations.minNumberIs", { count: 1 })}
              </Typography>
            )}
          </Grid>
          <Grid item desktop={3} tablet={6} mobile={6}>
            <TextField
              {...methods.register("salary", {
                onChange: handleChange,
              })}
              error={Boolean(methods.formState.errors.salary)}
              value={state.salary}
              name="salary"
              type="text"
              onKeyPress={(event) => {
                if (!regexNumberAndCommon.test(event.key)) {
                  event.preventDefault()
                }
              }}
              onPaste={(e) => e.preventDefault()}
              label={t("jobs.salary")}
            />
            {errorOptions.salary && (
              <Typography color="mockup.error50" fontWeight="500">
                {errorOptions.salary?.type === TypesError.MIN &&
                  t("validations.minNumberIs", { count: 1 })}
                {errorOptions.salary?.type === TypesError.MAX &&
                  t("validations.maxNumberIs", { count: 100000 })}
                {errorOptions.salary?.type === TypesError.ISHUNDREDTH &&
                  errorOptions.salary?.message}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid>
          <Grid container my={2}>
            <RichEditor
              addjob
              state={richEditorResponsibilities}
              setState={setRichEditorResponsibilities}
              placeholder={t("jobs.responsibilities")}
            />
          </Grid>
          <Grid container>
            <RichEditor
              addjob
              state={richEditorQualifications}
              setState={setRichEditorQualifications}
              placeholder={t("jobs.qualifications")}
            />
          </Grid>
        </Grid>
      </Box>

      {id === "editJob" && (
        <ArrowButton
          boxProps={{ mt: 2 }}
          onClickLeft={prevJob}
          onClickRight={nextJob}
          onDisabledLeft={isDisplayArrow.left}
          onDisabledRight={isDisplayArrow.right}
        />
      )}
    </form>
  )
}

export default AddJob
