import React from "react"

import { ReactComponent as IconPiggyBank } from "@common_assets/svg/piggy-bank.svg"
import { ReactComponent as QuestionMark } from "@common_assets/svg/question.svg"
import { ReactComponent as IconTransfer } from "@common_assets/svg/transfer.svg"
import { ReactComponent as WandIcon } from "@common_assets/svg/wand-solid.svg"
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { useQueryStudentSavings } from "api/reactQuery/queries/savings"
import { useQuerySavingsSnapshot } from "api/reactQuery/queries/snapshot"
import { IResponseSnapshots } from "api/reactQuery/queries/snapshots.types"
import Coin from "components/common/icon/coin"
import { useDialog } from "hooks/dialog"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { PrimaryTile } from "styles/common/tile"
import { progressColorSchema } from "utils/progressColorSchema"
import { isStudent } from "utils/roleCheck"

import AccountTransferDialog from "../../../common/accountTransferDialog"

const SavingSnapshot = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { data } = useQuerySavingsSnapshot()

  const { isOpen, handleOpen, handleClose } = useDialog()
  const { data: dataListingAccount } = useQueryStudentSavings()

  const twoOlderSnapshot = (data?: IResponseSnapshots) => {
    if (!data) return
    const sort = data?.goals.sort((a, b) => {
      return b.goal_progress - a.goal_progress
    })
    return sort?.slice(0, 2)
  }

  const userInfo = useAppSelector(selectUser)
  const isInterestOption =
    userInfo && isStudent(userInfo.user) && userInfo.user.has_interest_option

  return (
    <PrimaryTile px={4} py={2} mb={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        columnGap={1}
      >
        <Typography fontSize="24px" lineHeight="36px" color="mockup.neutral10">
          {t("dashboardStudent.savingSnapshot")}
        </Typography>
        <Link to={RoutePath.ACCOUNTS}>
          <Button variant="outlined">
            {t("dashboardStudent.viewSavings")}
          </Button>
        </Link>
      </Stack>
      {isInterestOption && (
        <Stack direction="row" columnGap={1}>
          <Stack
            border={`1px solid ${theme.palette.mockup.primary95}`}
            p={2}
            flex="1"
            borderRadius={1}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" flexBasis="80%">
                {t("dashboardStudent.totalInterestEarned")}
              </Typography>
              <Tooltip title="This is all of the money you earned just by having money in your savings account!">
                <QuestionMark width="16px" height="16px" />
              </Tooltip>
            </Stack>
            <Stack>
              <Coin amount={data?.data.total_interest_earned ?? 0} />
            </Stack>
          </Stack>
          <Stack
            border={`1px solid ${theme.palette.mockup.primary95}`}
            p={2}
            flex="1"
            borderRadius={1}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" flexBasis="80%">
                {t("dashboardStudent.projectedMonthlyInterest")}
              </Typography>
              <Tooltip
                title={t(
                  "myActivity.thisIsAllOfTheMoneyYouEarnedJustByHoldingMoney"
                )}
              >
                <QuestionMark width="16px" height="16px" />
              </Tooltip>
            </Stack>
            <Stack>
              <Coin amount={data?.data.projected_monthly_interest ?? 0} />
            </Stack>
          </Stack>
        </Stack>
      )}
      <Stack rowGap={2} mt={1}>
        {!data?.data.goals.length && (
          <Stack
            sx={{
              backgroundColor: theme.palette.mockup.primary70,
              borderRadius: 3,
              p: 3,
              rowGap: 3,
            }}
          >
            <IconPiggyBank />
            <Typography variant="subtitle1" color="mockup.neutral10">
              {t("myActivity.saveForYourGoals")}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="500"
              color="mockup.neutral10"
            >
              {t("myActivity.makeSavingForTheFutureEasyBySettingAGoal")}
            </Typography>
            <Link to={RoutePath.ACCOUNTS}>
              <Button style={{ width: "max-content" }}>
                {t("myActivity.addNewGoal")}
              </Button>
            </Link>
          </Stack>
        )}
        {twoOlderSnapshot(data?.data)?.map((snapshot) => (
          <Stack
            key={snapshot.id}
            sx={{
              backgroundColor: theme.palette.mockup.primary95,
              borderRadius: "16px",
              p: 2,
              rowGap: 1,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box display="flex" columnGap={1}>
                <Box width="24px" height="24px">
                  {!snapshot.icon_url ? (
                    <WandIcon />
                  ) : (
                    <img
                      src={snapshot.icon_url}
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  )}
                </Box>
                <Typography pr={3} sx={{ wordBreak: "break-all" }}>
                  {snapshot.title}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Coin
                  amount={snapshot.current_amount}
                  valueTextProps={{
                    fontSize: "18px",
                    color: theme.palette.mockup.grey70,
                  }}
                />
                <Typography
                  variant="body3"
                  sx={{
                    transform: "translateY(10px)",
                    fontWeight: "500",
                  }}
                >
                  /${snapshot.total_amount}
                </Typography>
              </Box>
            </Stack>
            <Box
              sx={{
                height: "20px",
                width: "100%",
                backgroundColor: progressColorSchema(
                  snapshot.goal_progress,
                  theme
                ).main,
                borderRadius: "8px",
                display: "flex",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: `calc(${snapshot.goal_progress / 100} * 100%)`,
                  backgroundColor: progressColorSchema(
                    snapshot.goal_progress,
                    theme
                  ).second,
                  borderBottomLeftRadius: "8px",
                  borderTopLeftRadius: "8px",
                }}
              />
              <Typography
                sx={{ position: "absolute", right: "10px", top: "0" }}
              >
                {snapshot.goal_progress}%
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
      <Box sx={{ textAlign: "end", mt: 1 }}>
        <Button onClick={handleOpen} endIcon={<IconTransfer />}>
          {t("myActivity.transfer")}
        </Button>
      </Box>
      <AccountTransferDialog
        isOpen={isOpen}
        handleClose={handleClose}
        data={dataListingAccount?.data}
      />
    </PrimaryTile>
  )
}
export default SavingSnapshot
