import { FC, useEffect } from "react"

import { useDialog } from "hooks/dialog"
import { useNavigate } from "react-router"
import { RoutePath } from "routes/Route.types"
import { IObjectStudentDetailed } from "ts/interfaces/Student"

import TransactionsDialogForm from "../transactionsDialogForm"
import { ListItemActions } from "../ViewCommonElementsContainer.types"

interface IProps {
  action: {
    actionName: ListItemActions
    chosenItem: IObjectStudentDetailed
  } | null
  resetActionState: () => void
}

const ListItemActionsHandler: FC<IProps> = ({ action, resetActionState }) => {
  const { isOpen, handleClose, handleOpen } = useDialog()
  const navigate = useNavigate()

  useEffect(() => {
    if (!action) return
    if (action.actionName === "sendTransaction") {
      handleOpen()
    }
    if (action.actionName === "visitProfile") {
      navigate(
        `${RoutePath.STUDENT.replace(":studentId", `${action.chosenItem.id}`)}`
      )
    }
  }, [action])

  if (!action) return null

  return (
    <TransactionsDialogForm
      isOpen={isOpen}
      action={action.actionName}
      handleClose={() => {
        resetActionState()
        handleClose()
      }}
      studentsIds={[action.chosenItem.id]}
      onSuccess={() => {
        handleClose()
        resetActionState()
      }}
    />
  )
}

export default ListItemActionsHandler
