import React, { Dispatch, useState } from "react"

import { ReactComponent as IconClock } from "@common_assets/svg/clock.svg"
import { ReactComponent as Expenses } from "@common_assets/svg/expenses.svg"
import { ReactComponent as Fine } from "@common_assets/svg/finest.svg"
import { ReactComponent as GreenDollar } from "@common_assets/svg/greenDollar.svg"
import { ReactComponent as Purchase } from "@common_assets/svg/purchase.svg"
import { ReactComponent as Refund } from "@common_assets/svg/refund.svg"
import { ReactComponent as Reset } from "@common_assets/svg/reset.svg"
import { ReactComponent as Salary } from "@common_assets/svg/salary.svg"
import { ReactComponent as Trash } from "@common_assets/svg/trash.svg"
import {
  Box,
  Fab,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { IStudentTransactionTeacherSide } from "api/reactQuery/queries/transaction.types"
import Coin from "components/common/icon/coin"
import IconWrapper from "components/common/icon/iconWrapper"
import { useDialog } from "hooks/dialog"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { TypeTransaction } from "ts/enums/Transaction"
import { showDecimal } from "utils/decimalNumber"

import DialogTransactions from "../dialogTransactions"

interface IProps {
  transactions: IStudentTransactionTeacherSide[]
  setDataTransactions: Dispatch<
    React.SetStateAction<IStudentTransactionTeacherSide[]>
  >
}

const MainBox = styled(Box)(({ theme }) => ({
  "&:nth-child(2n)": {
    backgroundColor: theme.palette.mockup.primary99,
    margin: "0 -24px",
    paddingRight: "24px",
    paddingLeft: "24px",
  },
  paddingTop: "16px",
}))

const MobileTable = ({ transactions, setDataTransactions }: IProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams()
  const [studentTransaction, setStudentTransaction] =
    useState<IStudentTransactionTeacherSide>()

  const { isOpen, handleOpen, handleClose } = useDialog()

  return (
    <Stack>
      <Stack
        bgcolor={(theme) => theme.palette.mockup.primary95}
        direction="row"
        alignItems="end"
        minHeight="48px"
        margin="0 -8px"
      />
      <Stack>
        <TransitionGroup component={null}>
          {transactions.map((studentInfo) => (
            <CSSTransition
              key={studentInfo.id}
              timeout={500}
              classNames="animation-fade"
            >
              <MainBox key={studentInfo.id}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item display="flex" alignItems="center">
                    <Box sx={{ mr: 2 }} position="relative">
                      {studentInfo.booked_at === null && (
                        <IconWrapper
                          height="19px"
                          width="19px"
                          sx={{
                            position: "absolute",
                            top: "1px",
                            right: "2px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconClock />
                        </IconWrapper>
                      )}
                      {studentInfo.type === TypeTransaction.BONUS && (
                        <GreenDollar />
                      )}
                      {studentInfo.type === TypeTransaction.SALARY && (
                        <Salary />
                      )}
                      {studentInfo.type === TypeTransaction.FINE && <Fine />}
                      {studentInfo.type === TypeTransaction.EXPENSE && (
                        <Expenses />
                      )}
                      {studentInfo.type === TypeTransaction.PURCHASE && (
                        <Purchase />
                      )}
                      {studentInfo.type === TypeTransaction.REFUND && (
                        <Refund />
                      )}
                      {studentInfo.type === TypeTransaction.RESET && <Reset />}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: theme.palette.mockup.neutral40,
                          textAlign: "left",
                        }}
                      >
                        {t("studentProfile.classAndTeacher")}
                      </p>
                      <p
                        style={{
                          color: theme.palette.mockup.neutral10,
                        }}
                      >
                        {studentInfo.class?.name}
                      </p>
                      <Typography
                        variant="body3"
                        color="mockup.neutral10"
                        fontWeight="500"
                      >
                        {studentInfo.teacher === null ? (
                          <span>-</span>
                        ) : (
                          studentInfo.teacher?.last_name
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Fab
                      size="small"
                      onClick={() => {
                        handleOpen()
                        setStudentTransaction(studentInfo)
                      }}
                    >
                      <Trash />
                    </Fab>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  mt="20px"
                  mb="14px"
                >
                  <Tooltip title={studentInfo.title}>
                    <Grid item display="flex" flexDirection="column" mobile={6}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: theme.palette.mockup.neutral40,
                          fontWeight: "500",
                        }}
                      >
                        {t("studentProfile.title")}
                      </Typography>
                      <Typography
                        sx={{
                          maxWidth: "80%",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: theme.palette.mockup.neutral20,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {studentInfo.title}
                      </Typography>
                    </Grid>
                  </Tooltip>

                  <Grid
                    item
                    display="flex"
                    justifyContent="flex-end"
                    mobile={6}
                  >
                    <Box textAlign="end" mr={3}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: theme.palette.mockup.neutral40,
                          fontWeight: "500",
                        }}
                      >
                        {t("studentProfile.value")}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        {studentInfo.amount < 0 ? (
                          <Coin
                            amount={showDecimal(
                              `${studentInfo.amount}`.slice(1)
                            )}
                            sign="-"
                          />
                        ) : (
                          <Coin
                            amount={showDecimal(`${studentInfo.amount}`)}
                            sign="+"
                          />
                        )}
                      </Box>
                    </Box>
                    <Box textAlign="end">
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: theme.palette.mockup.neutral20,
                        }}
                      >
                        {t("studentProfile.balance")}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        {studentInfo.booked_at === null &&
                        studentInfo.balance === null ? (
                          "-"
                        ) : (
                          <Coin amount={`${studentInfo.balance}`} />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </MainBox>
            </CSSTransition>
          ))}
          <DialogTransactions
            isOpen={isOpen}
            setDataTransactions={setDataTransactions}
            onClose={handleClose}
            transaction={studentTransaction && studentTransaction}
            params={params}
          />
        </TransitionGroup>
      </Stack>
    </Stack>
  )
}

export default MobileTable
