import React, {
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"

import {
  Autocomplete,
  TextField as TextFieldMui,
  Box,
  Button,
  Typography,
  Stack,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { IStudentCandidate } from "ts/interfaces/Student"

interface IProps {
  onChange: (value: IStudentCandidate | null) => void
  onAddNewStudentClick: () => void
  isLoading: boolean
  isError: boolean
  options: IStudentCandidate[]
  setIsChange: React.Dispatch<SetStateAction<boolean>>
}

const StudentsSearch: FC<IProps> = ({
  onChange,
  onAddNewStudentClick,
  isLoading,
  isError,
  options,
  setIsChange,
}) => {
  const { t } = useTranslation()
  const { ref, inView } = useInView()
  const [optionPage, setOptionPage] = useState(10)
  const [info, setInfo] = useState("")
  const [state, setState] = useState<IStudentCandidate[]>([])
  const visibleOptionsRef = useRef([...options])

  const [autocompleteValue, setAutocompleteValue] =
    useState<IStudentCandidate | null>(null)

  const isMountedRef = useRef(false)

  useEffect(() => {
    visibleOptionsRef.current = [...options]
  }, [options])

  useEffect(() => {
    const temporaryFile = [...options].sort((a, b) =>
      a.first_name.localeCompare(b.first_name)
    )
    setState(temporaryFile.slice(0, optionPage))
  }, [options, optionPage])

  useEffect(() => {
    if (inView) {
      setOptionPage((prev) => prev + 50)
    }
  }, [inView])

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  })

  return (
    <Autocomplete
      ListboxProps={{
        role: "list-box",
      }}
      limitTags={2}
      disableCloseOnSelect
      value={autocompleteValue}
      sx={{ mb: "48px" }}
      noOptionsText={t("noOptions")}
      loadingText={t("loading")}
      loading={isLoading}
      options={info.length > 2 && autocompleteValue === null ? options : state}
      onChange={(_, value) => {
        onChange(value)
        setAutocompleteValue(value)
      }}
      renderInput={(params) => (
        <TextFieldMui
          {...params}
          error={isError}
          helperText={isError && t("validations.anErrorOccured")}
          label={`${t("students.searchForStudentNameOrAddNew")}...`}
        />
      )}
      onInputChange={(event, v) => {
        event.type === "change" ? setIsChange(true) : setIsChange(false)
        setInfo(v)
        visibleOptionsRef.current = [
          ...options.filter(
            (el) =>
              `${el.login}`.toLowerCase().includes(v.toLowerCase()) ||
              `${el.first_name} ${el.last_name}`
                .toLowerCase()
                .includes(v.toLowerCase())
          ),
        ]
      }}
      isOptionEqualToValue={(option, value) => option.login === value.login}
      filterOptions={(options, state) => {
        return options.filter((option) => {
          const login = option.login?.toLowerCase() || ""
          const name =
            `${option?.first_name} ${option?.last_name}`?.toLowerCase() || ""
          const inputValue = state.inputValue.toLowerCase() || ""
          return login.includes(inputValue) || name.includes(inputValue)
        })
      }}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      renderOption={(props, option) => {
        const idArray = props?.id?.split("-")
        const index = (idArray ? idArray[3] : undefined) as undefined | string

        return (
          <Fragment key={option.id}>
            <li {...props}>
              <Stack>
                <Typography>
                  {`${option.first_name} ${option.last_name}`}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.mockup.neutral10Opacity,
                    fontWeight: 600,
                    overflowWrap: "break-word",
                  })}
                >
                  {`${option.login}`}
                </Typography>
              </Stack>
            </li>
            {index === `${state.length - 1}` && <Box ref={ref} />}
            {index === `${visibleOptionsRef.current.length - 1}` && (
              <Box
                width="100%"
                bgcolor="mockup.primary95"
                display="flex"
                justifyContent="center"
                mb="-8px"
                py="4px"
              >
                <Button variant="text" onClick={onAddNewStudentClick}>
                  {t("students.addNewStudent")}
                </Button>
              </Box>
            )}
          </Fragment>
        )
      }}
    />
  )
}

export default StudentsSearch
