import { FC } from "react"

import {
  Box,
  Checkbox,
  CheckboxProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import Coin from "components/common/icon/coin"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { selectDisplaySavings } from "store/displayMode/displayMode.selectors"
import { BigAvatar } from "styles/common/avatar"

interface IProps {
  checkboxProps: CheckboxProps
  fullName: string
  jobs: string[]
  avatar?: string
  titleSavings: string
  titleBookedBalance: string
  titleTotalBalance: string
  titlePendingBalance: string
  valueSavings: number
  valueBookedBalance: number
  valueTotalBalance: number
  valuePendingBalance: number
  savingsAccountOption?: boolean
}

const StudentItemCard: FC<IProps> = ({
  checkboxProps,
  fullName,
  jobs,
  avatar,
  valueSavings,
  valueBookedBalance,
  valueTotalBalance,
  titlePendingBalance,
  valuePendingBalance,
  savingsAccountOption,
}) => {
  const { t } = useTranslation()
  const isTablet = useIsBreakpointUp("tablet")
  const switchState = useAppSelector(selectDisplaySavings)

  return (
    <Stack
      p="24px 16px"
      position="relative"
      width="100%"
      border={(theme) => `2px solid ${theme.palette.mockup.primary95}`}
      borderRadius="16px"
      alignItems="center"
      bgcolor={(theme) => theme.palette.common.white}
    >
      <Box position="absolute" top="16px" left="16px">
        <Checkbox {...checkboxProps} />
      </Box>
      <BigAvatar src={avatar} alt="avatar" />
      <Typography
        mt={{ mobile: "8px", tablet: "16px" }}
        mb="4px"
        fontSize="18px"
        lineHeight="24px"
        color="mockup.grey70"
        align="center"
        sx={{ wordBreak: "break-all" }}
      >
        {fullName}
      </Typography>
      <Tooltip title={jobs.join(", ")}>
        <Typography
          mb="16px"
          variant="subtitle2"
          color="mockup.grey70"
          fontWeight={500}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxWidth="100%"
        >
          {jobs.length ? jobs.join(", ") : t("students.noJobs")}
        </Typography>
      </Tooltip>
      {savingsAccountOption && switchState && (
        <>
          <Stack
            px="24px"
            pt="24px"
            pb={{ mobile: "8px", tablet: "6px" }}
            bgcolor={(theme) => theme.palette.secondary.light}
            width="calc(100% + 32px)"
            marginX="-16px"
            gap="8px"
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant={isTablet ? "subtitle1" : "subtitle2"}
              color={(theme) => theme.palette.mockup.grey70}
              letterSpacing="-0.005em"
            >
              {t("students.checkings")}:
            </Typography>
            <Coin
              amount={valueBookedBalance}
              valueTextProps={{
                fontSize: "18px",
                color: (theme) => theme.palette.mockup.grey70,
              }}
            />
          </Stack>
          <Stack
            px="24px"
            py={{ mobile: "8px", tablet: "6px" }}
            bgcolor={(theme) => theme.palette.secondary.light}
            width="calc(100% + 32px)"
            marginX="-16px"
            gap="8px"
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant={isTablet ? "subtitle1" : "subtitle2"}
              color={(theme) => theme.palette.mockup.grey70}
              letterSpacing="-0.005em"
            >
              {t("students.savings")}:
            </Typography>
            <Coin
              amount={valueSavings}
              valueTextProps={{
                fontSize: "18px",
                color: (theme) => theme.palette.mockup.grey70,
              }}
            />
          </Stack>
        </>
      )}
      <Stack
        px="24px"
        py={{ mobile: "8px", tablet: "6px" }}
        bgcolor={(theme) => theme.palette.secondary.light}
        width="calc(100% + 32px)"
        marginX="-16px"
        gap="8px"
        direction="row"
        justifyContent="space-between"
      >
        <Typography
          variant={isTablet ? "subtitle1" : "subtitle2"}
          color={(theme) => theme.palette.mockup.grey70}
          letterSpacing="-0.005em"
        >
          {titlePendingBalance}:
        </Typography>
        <Coin
          amount={valuePendingBalance}
          valueTextProps={{
            fontSize: "18px",
            color: (theme) => theme.palette.mockup.grey70,
          }}
        />
      </Stack>
      <Stack
        px="24px"
        pt={{ mobile: "8px", tablet: "6px" }}
        pb="24px"
        bgcolor={(theme) => theme.palette.secondary.light}
        width="calc(100% + 32px)"
        marginX="-16px"
        gap="8px"
        direction="row"
        justifyContent="space-between"
      >
        <Typography
          variant={isTablet ? "subtitle1" : "subtitle2"}
          color={(theme) => theme.palette.mockup.grey70}
          letterSpacing="-0.005em"
        >
          {t("students.balance")}:
        </Typography>
        <Coin
          amount={valueTotalBalance}
          valueTextProps={{
            fontSize: "18px",
            color: (theme) => theme.palette.mockup.grey70,
          }}
        />
      </Stack>
    </Stack>
  )
}

export default StudentItemCard
