export enum Type {
  salary = "salary",
  expense = "expense",
  bonus = "bonus",
  fine = "fine",
  purchase = "purchase",
  refund = "refund",
  reset = "reset",
  pending = "pending",
  allTransactions = "allTransactions",
}
