export const myActivity = {
  yourCurrentBalance: "Your Current Balance",
  youDontHaveAnyActivityYet: "You don’t have any activity yet",
  checkBackLaterOnceYouMadeYourFirstDepositOrWithdraw:
    "Check back later once you've made your first deposit or withdraw!",
  pendingTransactions: "Pending transactions",
  type: "Type",
  class: "Class",
  classAndTeacher: "Class & teacher",
  teacher: "Teacher",
  title: "Title",
  date: "Date",
  withdraw: "Withdraw",
  deposit: "Deposit",
  balance: "Balance",
  youCurrentlyHaveNoPendingTransactions:
    "You currently have no pending transactions.",
  youCurrentlyHaveNoBookedTransactions:
    "You currently have no booked transactions.",
  congratulationsOnMakingAllOfYourDepositsAndWithdraws:
    "Congratulations on making all of your deposits and withdraws!",
  transactionHistory: "Transaction history",
  thereAreNoMorePendingTransactions: "There are no more pending transactions",
  thereAreNoMoreHistoryTransactions: "There are no more history transactions",
  searchFor: "Search for",
  setDate: "Set date",
  dateRange: "Date range",
  transactionType: "Transaction type",
  all_accounts: "All accounts",
  transfer: "Transfer",
  yourSavings: "Your Savings:",
  pleaseReloadYourSiteToGetChanges: "Please reload your site to get changes",
  totalBalance: "Total Balance",
  checkingAccount: "Checking Account",
  interest: "Interest",
  thisGraphShowsYourChecking:
    "This graph shows your checking account balance over the last 30 days.",
  thisGraphShowsYourSavings:
    "This graph shows your savings account balance over the last 30 days.",
  interestEarnedOnSavingsIsTheInterestRateMultiplied:
    "Interest earned on savings is the interest rate multiplied by the balance in the account.",
  thisIsAllOfTheMoneyYouEarnedJustByHavingMoney:
    "This is all of the money you earned just by having money in your savings account!",
  thisIsAllOfTheMoneyYouEarnedJustByHoldingMoney:
    "This is all of the money you earned just by holding money in your savings account!",
  thisIsHowMuchMoneyYouWillEarnInInterestThisMonth:
    "This is how much money you will earn in interest this month! Interest earned on savings is the interest rate multiplied by the balance in the account.",
  noTransactionsDuringThisDateRange: "No transactions during this date range",
  loadMore: "Load More",
  entireSchoolYear: "Entire School Year",
  setTheDate: "Set date range",
  allTypes: "All Types",
  pending: "PENDING",
  saveForYourGoals: "Save for your goals!",
  makeSavingForTheFutureEasyBySettingAGoal:
    "Make saving for the future easy by setting a goal and watch your savings grow!",
  addNewGoal: "Add new goal",
}
